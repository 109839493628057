export interface CostCenter {
  id: number;
  code: string;
  name: string;
  branchCode?: string;
  status?: keyof typeof PossibleCostCenterStatuses;
}

export enum PossibleCostCenterStatuses {
  'OPEN',
  'CLOSE',
}
