import api from '@services/api';
import { NewProductRequest, Product } from '../models/product';

/**
 * <p>
 * API endpoints that cover all resource product related CRUD calls
 */

export const productapi = api.injectEndpoints({
  endpoints: (builder) => ({
    // GET
    Products: builder.query<[Product], void>({
      query: () => ({
        url: encodeURI(`/backoffice/products`),
        method: 'GET',
      }),
      providesTags: ['Products'],
    }),
    addRoleInProduct: builder.mutation<Product, NewProductRequest>({
      query: (product) => ({
        url: encodeURI(`/backoffice/products/addRole`),
        method: 'PUT',
        body: product,
      }),
      invalidatesTags: ['Products'],
    }),
    removeRoleInProduct: builder.mutation<Product, NewProductRequest>({
      query: (product) => ({
        url: encodeURI(`/backoffice/products/removeRole`),
        method: 'PUT',
        body: product,
      }),
      invalidatesTags: ['Products'],
    }),
  }),
});

export const { useProductsQuery, useAddRoleInProductMutation, useRemoveRoleInProductMutation } = productapi;
