import AutomaticPeriod from '@/pages/automaticPeriod';
import Period from '@/pages/period';
import Products from '@/pages/products';
import Received from '@/pages/received';
import Redirect from '@/pages/redirecting';
import Requested from '@/pages/requested';
import PERMISSION_ROLES, { PAGE_NAMES } from '@constants/roles';
import RequireAuth from '@features/auth/RequireAuth';
import RequireLogin from '@features/auth/RequireLogin';
import Costcenter from '@pages/costcenter';
import CostcenterBreadcrumb from '@pages/costcenterBreadcrumb';
import CostcenterDetails from '@pages/costcenterDetails';
import Layout from '@pages/layout';
import Login from '@pages/login';
import PeriodDetails from '@pages/periodDetails';
import PersonProductRoles from '@pages/personProductRoles';

import { Route, Routes } from 'react-router-dom';

function AppRoute() {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route element={<RequireLogin />}>
        <Route element={<Layout />}>
          {/* public routes */}
          <Route path="/" element={<Redirect />} />
          {/* <Route path="/dashboard" element={<Dashboard />} /> */}
          {/* protected routes for ADMIN */}
          <Route element={<RequireAuth roles={PERMISSION_ROLES[PAGE_NAMES.PERIODO]} />}>
            <Route path="/automatic-period" element={<AutomaticPeriod />} />
            <Route path="/period" element={<Period />} />
          </Route>

          <Route element={<RequireAuth roles={PERMISSION_ROLES[PAGE_NAMES.SUPER]} />}>
            <Route path="/product" element={<Products />} />
          </Route>

          <Route element={<RequireAuth roles={PERMISSION_ROLES[PAGE_NAMES.PERMISSION_BY_USER]} />}>
            <Route path="/permission-by-product" element={<PersonProductRoles />} />
          </Route>

          <Route element={<PeriodDetails />}>
            <Route element={<RequireAuth roles={PERMISSION_ROLES[PAGE_NAMES.COSTCENTER]} />}>
              <Route element={<CostcenterBreadcrumb />}>
                <Route path="/costcenter/:code" element={<CostcenterDetails />} />{' '}
                <Route path="/costcenter" element={<Costcenter />} />
              </Route>
            </Route>
            <Route element={<RequireAuth roles={PERMISSION_ROLES[PAGE_NAMES.SOLICITAÇÕES]} />}>
              <Route path="/received" element={<Received />} />
              <Route path="/requested" element={<Requested />} />
            </Route>
          </Route>
        </Route>
      </Route>
    </Routes>
  );
}

export default AppRoute;
