import api from '@services/api';
import { GeneratePeriodFilesRequest, PeriodFilesRequest, PeriodFilesResponse } from '../models/periodFiles';

/**
 * <p>
 * API endpoints that cover all resource period file related CRUD calls
 */
export const periodFile = api.injectEndpoints({
  endpoints: (builder) => ({
    // GET
    getPeriodFilesById: builder.query<PeriodFilesResponse, PeriodFilesRequest>({
      query: (input) => ({
        url: encodeURI(`/sharing/period-file/byPeriod?periodId=${input.periodId}`),
        method: 'GET',
      }),
      providesTags: ['PeriodFile'],
    }),
    generatePeriodFiles: builder.mutation<any, GeneratePeriodFilesRequest>({
      query: (input) => ({
        url: '/sharing/period-file/export',
        method: 'POST',
        body: input,
      }),
      invalidatesTags: ['PeriodFile'],
    }),
  }),
});

export const { useGeneratePeriodFilesMutation, useGetPeriodFilesByIdQuery } = periodFile;
