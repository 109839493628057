import api from '@services/api';
import { PaginationResponse } from '../models/pagination';
import { Product, Person, PersonProductRoles } from '../models/person';

interface PersonRequest {
  searchTerm?: string;
  page: number;
  size: number;
  timestamp: number;
}

interface PersonByEmailAndProductRequest {
  email?: string;
  productCode?: string;
}

export interface SharingsRequestedRequest {
  period: number;
  email?: string;
  status?: string;
  searchTerm?: string;
  page: number;
  size: number;
}

interface PersonProductRole {
  email?: string;
  code?: string;
  roles: Array<string>;
}

/**
 * <p>
 * API endpoints that cover all resource sharing CRUD calls
 */
export const personApi = api.injectEndpoints({
  endpoints: (builder) => ({
    // GET
    person: builder.query<PaginationResponse<Person>, PersonRequest>({
      query: (input: PersonRequest) => ({
        url: encodeURI(
          `/backoffice/person/searchByTerm?${input.searchTerm ? `searchTerm=${input.searchTerm}&` : ''}size=${
            input.size
          }&page=${input.page}`,
        ),
      }),
    }),
    products: builder.query<Array<Product>, void>({
      query: () => ({
        url: encodeURI(`/backoffice/products`),
      }),
    }),
    personProductsRoles: builder.query<PaginationResponse<PersonProductRoles>, PersonRequest>({
      query: (input: PersonRequest) => ({
        url: encodeURI(
          `/backoffice/person-product-role/searchByTerm?${
            input.searchTerm ? `searchTerm=${input.searchTerm}&` : ''
          }size=${input.size}&page=${input.page}`,
        ),
      }),
    }),
    personProductsRolesByEmailProduct: builder.query<PersonProductRoles, PersonByEmailAndProductRequest>({
      query: (input: PersonByEmailAndProductRequest) => ({
        url: encodeURI(
          `/backoffice/person-product-role/searchByEmailAndProduct?email=${input.email}&productCode=${input.productCode}`,
        ),
      }),
    }),
    updatePersonProductRole: builder.mutation<PersonProductRole, PersonProductRole>({
      query: (personProductRole) => ({
        url: `/backoffice/person-product-role`,
        method: 'PUT',
        body: personProductRole,
      }),
    }),
  }),
});

export const {
  usePersonQuery,
  usePersonProductsRolesByEmailProductQuery,
  useProductsQuery,
  usePersonProductsRolesQuery,
  useUpdatePersonProductRoleMutation,
} = personApi;
