import { Outlet } from 'react-router-dom';
import PeriodDetails from '@/components/PeriodDetails';
import styled from '@emotion/styled';

const PeriodDetailsScreen = styled.div`
  width: 100%;
  margin-right: 24px;
  margin-bottom: 223px;
  display: flex;
  flex-direction: column;
`;

function PeriodDetailsPage() {
  return (
    <PeriodDetailsScreen>
      <PeriodDetails />
      <Outlet />
    </PeriodDetailsScreen>
  );
}
export default PeriodDetailsPage;
