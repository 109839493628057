import { useState } from 'react';

import InputBase from '@mui/material/InputBase';
import Paper from '@mui/material/Paper';
import searchIcon from '@/icons/search.svg';

import SearchIcon from '@mui/icons-material/Search';
import { Typography, InputAdornment, Box, TextField, InputProps } from '@mui/material';

type CallbackFunction = (name: string) => void;

type SearchComponentProp = {
  updateSearchName: CallbackFunction;
  searchName: string | null;
  placeholder?: string;
  title?: string;
};

export default function SearchComponents(props: SearchComponentProp) {
  const { updateSearchName, searchName, placeholder, title } = props;
  const [name, setName] = useState<string | undefined>(searchName || '');
  const onChangeName = (value: string | undefined) => {
    setName(value);
    updateSearchName(value || '');
  };
  return (
    <div style={{ marginBottom: '16px', marginTop: '24px' }}>
      {title && (
        <Typography sx={{ fontWeight: 'bold', fontSize: '14px', color: '#4b4b4b', marginBottom: '4px' }}>
          {title}
        </Typography>
      )}
      <TextField
        placeholder={placeholder}
        size="small"
        sx={{ minWidth: '360px', height: '32px', fontSize: '13px' }}
        value={name}
        onChange={(e) => onChangeName(e.target.value)}
        InputProps={
          {
            endAdornment: (
              <InputAdornment position="end">
                <img src={searchIcon} alt={'icone de pesquisa' as string} />
              </InputAdornment>
            ),
            style: {
              paddingRight: '10px',
              paddingTop: '0px',
              paddingBottom: '0px',
              paddingLeft: '10px',
              fontSize: '13px',
              height: '32px',
            },
          } as InputProps
        }
      />
    </div>
  );
}
