import api from '@services/api';
import { PeriodConfiguration, PeriodConfigurationResponse } from '../models/periodConfiguration';

/**
 * <p>
 * API endpoints that cover all resource period configurations related CRUD calls
 */
export const periodConfiguration = api.injectEndpoints({
  endpoints: (builder) => ({
    // GET
    currentPeriodConfiguration: builder.query<PeriodConfigurationResponse, void>({
      query: () => ({
        url: encodeURI(`/sharing/period_configuration`),
        method: 'GET',
      }),
    }),
    savePeriodConfiguration: builder.mutation<PeriodConfigurationResponse, PeriodConfiguration>({
      query: (input) => ({
        url: '/sharing/period_configuration/save',
        method: 'POST',
        body: input,
      }),
    }),
  }),
});

export const { useCurrentPeriodConfigurationQuery, useSavePeriodConfigurationMutation } = periodConfiguration;
