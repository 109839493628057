import { ON_CHANGE_DELAY } from '@/app/constants/values';
import { SharingResource } from '@/app/models/summary';
import { SolicitationStatuses } from '@/app/models/utils/requests';
import { useCurrentPeriodQuery } from '@/app/services/periodApi';
import {
  useApproveAllMutation,
  useRejectAllMutation,
  useSharingsReceivedQuery,
} from '@/app/services/resourceSharingApi';
import RequestsGrid from '@/components/requests/RequestsGrid';
import RequestsInput from '@/components/requests/RequestsInput';

import { Typography } from '@mui/material';
import { GridRowSelectionModel } from '@mui/x-data-grid';
import { useKeycloak } from '@react-keycloak/web';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useThrottle } from 'react-use';

function Received() {
  const { keycloak } = useKeycloak();
  const { t } = useTranslation('received');
  const [search, setSearch] = useState('');
  const throttledSearch = useThrottle(search, ON_CHANGE_DELAY);
  const [statusSelect, setStatusSelect] = useState('OPENED' as keyof typeof SolicitationStatuses);
  const [showMine, setShowMine] = useState(true);
  const isPMO = keycloak.hasResourceRole('PMO', process.env.REACT_APP_KEYCLOAK_CLIENT_ID);
  const [ApproveRequests] = useApproveAllMutation();
  const [RejectRequests] = useRejectAllMutation();
  const { data: currentPeriod, isSuccess: hasPeriod } = useCurrentPeriodQuery();
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });

  const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([]);
  const {
    data: requestedSharings,
    isSuccess,
    isLoading,
  } = useSharingsReceivedQuery(
    {
      period: currentPeriod ? currentPeriod?.id : 0,
      page: paginationModel.page,
      size: paginationModel.pageSize,
      searchTerm: throttledSearch.length > 0 ? throttledSearch : undefined,
      status: statusSelect === 'all' ? undefined : statusSelect,
      ...(showMine && { email: keycloak?.tokenParsed?.email }),
    },
    { skip: !hasPeriod },
  );

  function handleApproveRequests() {
    ApproveRequests({
      ids: rowSelectionModel.map((i) => parseInt(i as string, 10)),
    });
  }

  function handleRejectRequests() {
    RejectRequests({
      ids: rowSelectionModel.map((i) => parseInt(i as string, 10)),
    });
  }

  const [rowCountState, setRowCountState] = useState(requestedSharings?.totalElements || 0);

  useEffect(() => {
    setRowCountState((prevRowCountState) =>
      requestedSharings?.totalElements !== undefined ? requestedSharings?.totalElements : prevRowCountState,
    );
  }, [requestedSharings?.totalElements, setRowCountState]);

  return (
    <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <Typography
        sx={{ fontWeight: 'bold', fontSize: '18px', color: '#4b4b4b', marginBottom: '8px', marginTop: '32px' }}
      >
        {t('Solicitações Recebidas')}
      </Typography>
      <RequestsInput
        search={search}
        setSearch={setSearch}
        statusSelect={statusSelect}
        setStatusSelect={setStatusSelect}
        showMine={showMine}
        setShowMine={setShowMine}
        isPMO={isPMO}
        canApprove={rowSelectionModel.length >= 1}
        canReject={rowSelectionModel.length >= 1}
        handleApproveRequests={handleApproveRequests}
        handleRejectRequests={handleRejectRequests}
      />
      <RequestsGrid
        isSuccess={isSuccess}
        isLoading={isLoading}
        rowCountState={rowCountState}
        content={requestedSharings?.content as SharingResource[]}
        pageOptions={[5]}
        paginationModel={paginationModel}
        setPaginationModel={setPaginationModel}
        rowSelectionModel={rowSelectionModel}
        setRowSelectionModel={setRowSelectionModel}
      />
    </div>
  );
}
export default Received;
