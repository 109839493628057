import { Product } from '@/app/models/product';
import {
  useAddRoleInProductMutation,
  useProductsQuery,
  useRemoveRoleInProductMutation,
} from '@/app/services/productApi';
import SearchComponents from '@/components/costcenter/SearchComponent';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Breadcrumbs,
  Button,
  InputBase,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { MutableRefObject, useRef, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

function Products() {
  const { t } = useTranslation('produtos');

  const tableEl = useRef() as unknown as MutableRefObject<HTMLInputElement>;
  const [searchProduct, setSearchProduct] = useState('');
  const [selectedProduct, setSelectedProduct] = useState(null as unknown as Product);
  const [removeRole] = useRemoveRoleInProductMutation();
  const [addRole] = useAddRoleInProductMutation();
  const [ruleName, setRuleName] = useState('');
  const { data: products } = useProductsQuery();
  useEffect(() => {
    if (selectedProduct && products) {
      setSelectedProduct(products?.find((p) => p.code === selectedProduct.code) || (null as unknown as Product));
    }
  }, [products, selectedProduct]);

  async function handleCreateProductRole() {
    const response = await addRole({
      code: selectedProduct.code,
      role: ruleName,
    });
    if ('data' in response) {
      toast.success(
        t('Regra adicionada ao produto', {
          position: toast.POSITION.TOP_RIGHT,
        }),
      );
    }
  }

  async function handleDeleteProductRole(exclude: string) {
    const response = await removeRole({
      code: selectedProduct.code,
      role: exclude,
    });
    if ('data' in response) {
      toast.success(
        t('Regra removida do produto', {
          position: toast.POSITION.TOP_RIGHT,
        }),
      );
    }
  }

  return (
    <div style={{ width: '100%' }}>
      {!selectedProduct ? (
        <>
          <Breadcrumbs aria-label="breadcrumb">
            <Typography
              sx={{ fontWeight: 'bold', fontSize: '18px', color: '#4b4b4b', marginBottom: '8px' }}
              onClick={() => setSelectedProduct(null as unknown as Product)}
            >
              {t('Produtos')}
            </Typography>
          </Breadcrumbs>
          <SearchComponents
            searchName={searchProduct}
            updateSearchName={setSearchProduct}
            placeholder={t('Digite o nome') as string}
          />
          <div>
            {true && (
              <TableContainer
                component={Paper}
                style={{ maxHeight: '60vh', width: '600px', marginTop: '42px' }}
                ref={tableEl}
              >
                <Table stickyHeader sx={{}} aria-label="simple table">
                  <TableHead>
                    <TableRow style={{ color: '#4B4B4B', height: '40px', fontSize: '13px' }}>
                      <TableCell
                        style={{ fontWeight: 'bold', paddingTop: '0px', paddingBottom: '0px', fontSize: '13px' }}
                      >
                        {t('Nome')}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {products
                      ?.filter((p) => p.name.includes(searchProduct))
                      ?.map((product: Product, i) => (
                        <TableRow
                          key={product.code}
                          sx={{
                            height: '40px',
                            border: '0px',
                            cursor: 'pointer',
                            backgroundColor: `${i % 2 === 0 ? '#FFFFFF' : '#FAFAFA'}`,
                            '&:last-child td, &:last-child th': { border: 0 },
                          }}
                          onClick={() => setSelectedProduct(product)}
                        >
                          <TableCell sx={{ paddingTop: '0px', paddingBottom: '0px', border: '0px', fontSize: '13px' }}>
                            {product.name}
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </div>
        </>
      ) : (
        <>
          <Breadcrumbs aria-label="breadcrumb">
            <Button
              sx={{
                fontSize: '18px',
                color: '#4b4b4b',
                marginBottom: '8px',
                textTransform: 'none',
                padding: '0px',
              }}
              onClick={() => setSelectedProduct(null as unknown as Product)}
            >
              {t('Produtos')}
            </Button>
            <Typography sx={{ fontWeight: 'bold', fontSize: '18px', color: '#4b4b4b', marginBottom: '8px' }}>
              {selectedProduct.name}
            </Typography>
          </Breadcrumbs>
          <div style={{ display: 'flex', marginTop: '24px' }}>
            <Paper
              component="form"
              sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 400, height: 32 }}
            >
              <InputBase
                placeholder={t('Nome da Regra') as string}
                size="small"
                sx={{ fontSize: '13px', ml: 1, flex: 1 }}
                value={ruleName}
                onChange={(e) => setRuleName(e.target.value)}
              />
            </Paper>
            <Button
              variant="primary"
              sx={{ marginLeft: '12px' }}
              disabled={ruleName.trim().length === 0}
              onClick={() => {
                handleCreateProductRole();
              }}
            >
              {t('Adicionar')}
            </Button>
          </div>
          {selectedProduct && (
            <TableContainer
              component={Paper}
              style={{ maxHeight: '60vh', width: '600px', marginTop: '42px' }}
              ref={tableEl}
            >
              <Table stickyHeader sx={{}} aria-label="simple table">
                <TableHead>
                  <TableRow style={{ color: '#4B4B4B', height: '40px', fontSize: '13px' }}>
                    <TableCell
                      style={{ fontWeight: 'bold', paddingTop: '0px', paddingBottom: '0px', fontSize: '13px' }}
                    >
                      {t('Regras')}
                    </TableCell>
                    <TableCell
                      align="right"
                      style={{ fontWeight: 'bold', paddingTop: '0px', paddingBottom: '0px', fontSize: '13px' }}
                    >
                      {t('Excluir')}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {selectedProduct.roles.map((role: string, i) => (
                    <TableRow
                      key={role}
                      sx={{
                        height: '40px',
                        border: '0px',
                        cursor: 'pointer',
                        backgroundColor: `${i % 2 === 0 ? '#FFFFFF' : '#FAFAFA'}`,
                        '&:last-child td, &:last-child th': { border: 0 },
                      }}
                    >
                      <TableCell sx={{ paddingTop: '0px', paddingBottom: '0px', border: '0px', fontSize: '13px' }}>
                        {role}
                      </TableCell>
                      <TableCell
                        align="right"
                        sx={{ paddingTop: '0px', paddingBottom: '0px', border: '0px', fontSize: '13px' }}
                      >
                        <Button
                          style={{
                            color: '#666666',
                            fontSize: '13px',
                            textTransform: 'initial',
                            borderRadius: '0px',
                          }}
                          onClick={() => handleDeleteProductRole(role)}
                        >
                          <DeleteIcon />
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </>
      )}
    </div>
  );
}
export default Products;
