import api from '@services/api';
import { CostCenterResource } from '../models/costCenterResource';
import { PaginationResponse } from '../models/pagination';

/**
 * <p>
 * API endpoints that covers all Contract CRUD calls
 */

interface QueryInput {
  email: string | null;
  page: number;
  bringClosed: boolean;
  orderBy: string;
  order: string;
  query?: string;
}

export const costCenterResourceApi = api.injectEndpoints({
  endpoints: (builder) => ({
    // SEARCH BY NAME
    costCenterResource: builder.query<PaginationResponse<CostCenterResource>, QueryInput>({
      query: (queryInput) => ({
        url: `/sharing/costCenterResource/search?${queryInput.email ? `email=${queryInput.email}&` : ''}bringClosed=${
          queryInput.bringClosed
        }&page=${queryInput.page}&size=20&sortKey=${
          queryInput.orderBy
        }&sortDirection=${queryInput.order.toUpperCase()}${queryInput.query ? `&searchTerm=${queryInput.query}` : ''}`,
        method: 'GET',
        validateStatus: (response) => response.status === 200 || response.status === 302,
      }),
      providesTags: ['Contract'],
    }),
  }),
});

export const { useCostCenterResourceQuery } = costCenterResourceApi;
