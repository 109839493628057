import { Button, Card, CardContent, MenuItem, Select, Typography } from '@mui/material';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';

type AutomaticPeriodSettingsProps = {
  openingDay: number;
  setOpeningDay: Dispatch<SetStateAction<number>>;
  closingDay: number;
  setClosingDay: Dispatch<SetStateAction<number>>;
  closingHour: dayjs.Dayjs | null;
  setClosingHour: Dispatch<SetStateAction<dayjs.Dayjs | null>>;
  handleSave: () => void;
};
function AutomaticPeriodSettings(props: AutomaticPeriodSettingsProps) {
  const { i18n, t } = useTranslation('automaticPeriod');
  const { openingDay, setOpeningDay, closingDay, setClosingDay, closingHour, setClosingHour, handleSave } = props;
  const [modified, setModified] = useState(false);
  return (
    <Card
      sx={{
        boxShadow: 3,
        display: 'flex',
        minHeight: '72px',
        alignItems: 'center',
        padding: '24px',
        paddingBottom: '0px',
        marginRight: '24px',
      }}
    >
      <CardContent sx={{ alignItems: 'center', padding: '0px', paddingBottom: '0px' }}>
        <div style={{ display: 'flex', marginBottom: '16px' }}>
          <div style={{ marginRight: '24px' }}>
            <Typography sx={{ fontWeight: 'bold', fontSize: '14px', color: '#4b4b4b', marginBottom: '4px' }}>
              {t('Dia de Abertura')}
            </Typography>
            <Select
              value={openingDay}
              size="small"
              label={t('Dia de Abertura')}
              sx={{ width: '160px' }}
              onChange={(e) => {
                setOpeningDay(e.target.value as number);
                setModified(true);
              }}
            >
              {[...Array(30).keys()].map((i) => (
                <MenuItem key={i} value={i + 1}>
                  {i + 1}
                </MenuItem>
              ))}
            </Select>
          </div>
          <div style={{ marginRight: '24px' }}>
            <Typography sx={{ fontWeight: 'bold', fontSize: '14px', color: '#4b4b4b', marginBottom: '4px' }}>
              {t('Dia de Fechamento')}
            </Typography>
            <Select
              value={closingDay}
              size="small"
              label={t('Dia de Fechamento')}
              sx={{ width: '160px' }}
              onChange={(e) => {
                setClosingDay(e.target.value as number);
                setModified(true);
              }}
            >
              {[...Array(30).keys()].map((i) => (
                <MenuItem key={i} value={i + 1}>
                  {i + 1}
                </MenuItem>
              ))}
            </Select>
          </div>
          <div style={{ marginRight: '24px' }}>
            <Typography sx={{ fontWeight: 'bold', fontSize: '14px', color: '#4b4b4b', marginBottom: '4px' }}>
              {t('Hora de Fechamento')}
            </Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={i18n.language}>
              <TimePicker
                ampm={false}
                slotProps={{ textField: { size: 'small' } }}
                value={closingHour}
                onChange={(newValue) => {
                  setClosingHour(newValue);
                  setModified(true);
                }}
              />
            </LocalizationProvider>
          </div>
        </div>
        <div>
          <Typography sx={{ fontWeight: 'bold', fontSize: '13px', color: '#666666', marginBottom: '12px' }}>
            {t('Atenção')}
          </Typography>

          <ul style={{ paddingLeft: '16px', marginBottom: '24px' }}>
            <li>
              <Typography sx={{ fontSize: '12px', color: '#888888' }}>
                {t(
                  'Um novo período será criado automaticamente todos os meses com as datas e horário informados acima, podendo ser alterado manualmente há qualquer momento.',
                )}
              </Typography>
            </li>
            <li>
              <Typography sx={{ fontSize: '12px', color: '#888888' }}>
                {t(
                  'Os gestores serão notificados sobre a data de abertura do período e sobre os rateios em aberto na data de fechamento do período.',
                )}
              </Typography>
            </li>
          </ul>

          <Button
            variant="primary"
            disabled={!modified}
            onClick={() => {
              setModified(false);
              handleSave();
            }}
          >
            {t('Salvar')}
          </Button>
        </div>
      </CardContent>
    </Card>
  );
}

export default AutomaticPeriodSettings;
