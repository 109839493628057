import { SharingResource } from '@app/models/summary';

export enum PossibleStatuses {
  'OPENED',
  'CLOSED',
}
export interface Period {
  id: number;
  initDate: string;
  endDate: string;
  endTime: string;
  status: keyof typeof PossibleStatuses;
}

export interface NewPeriodRequest {
  initDate: string;
  endDate: string;
  endTime: string;
}

export interface UpdatePeriodRequest {
  id: number;
  endDate: string;
  endTime: string;
}

export interface ClosePeriodRequest {
  id: number;
}

export interface ClosePeriodResponse {
  period: Period;
  sharings: Array<SharingResource>;
}
