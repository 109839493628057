// eslint-disable-next-line import/prefer-default-export
export enum SolicitationStatuses {
  'OPENED' = 'Pendente',
  'REJECTED' = 'Rejeitados',
  'APPROVED' = 'Aprovados',
  'all' = 'Todos',
}

export enum PeriodTabs {
  'HISTORY',
  'NOTPROCESSCONTRACT',
}
