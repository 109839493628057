import { Product, Person } from '@/app/models/person';
import { usePersonQuery } from '@/app/services/personProductRolesApi';
import { useProductsQuery } from '@/app/services/productApi';

import CreatePersonProductRole from '@/components/personProductRoles/CreatePersonProductRole';

import { Drawer, Box, InputAdornment, InputProps, TextField, Typography } from '@mui/material';

import EditIcon from '@mui/icons-material/Edit';
import searchIcon from '@/icons/search.svg';
import { ON_CHANGE_DELAY } from '@/app/constants/values';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

import { MutableRefObject, useCallback, useLayoutEffect, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useThrottle } from 'react-use';

interface SearchBarsProps {
  searchTerm: string;
  setSearchTerm: (arg0: string) => void;
}

export function SearchBar(props: SearchBarsProps) {
  const { searchTerm, setSearchTerm } = props;

  const { t } = useTranslation('costcenter');

  return (
    <Box
      component="form"
      sx={{
        '& .MuiTextField-root': { width: '25ch', ml: 0 },
      }}
      noValidate
      autoComplete="off"
    >
      <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', marginTop: '23px' }}>
        <div style={{ display: 'flex', width: '70%', margin: 'auto 0' }}>
          <TextField
            size="small"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            style={{ minWidth: '360px', height: '32px', fontSize: '13px' }}
            autoFocus
            placeholder={t('Pesquisar por email ou nome') as string}
            InputProps={
              {
                endAdornment: (
                  <InputAdornment position="end">
                    <img src={searchIcon} alt={t('icone de pesquisa') as string} />
                  </InputAdornment>
                ),
                style: {
                  paddingRight: '10px',
                  paddingTop: '0px',
                  paddingBottom: '0px',
                  paddingLeft: '10px',
                  fontSize: '13px',
                },
              } as InputProps
            }
          />
        </div>
      </div>
      <div />
    </Box>
  );
}

const myCustomNoRows = () => (
  <div style={{ display: 'flex', justifyContent: 'center', height: '360px', alignItems: 'center' }}>
    <Typography>Nenhum resultado encontrado.</Typography>
  </div>
);

type CallbackFunction = (v: Person | null) => void;

const columns = (setSelectedUser: CallbackFunction, combined: Array<Person>) => {
  const v: GridColDef[] = [
    {
      field: 'email',
      headerName: 'E-mail',
      width: 300,
      valueFormatter: ({ value }) => value.name,
      sortable: false,
    },
    {
      field: 'name',
      headerName: 'Nome',
      flex: 1,
      valueFormatter: ({ value }) => value.name,
      sortable: false,
    },
    {
      field: 'id',
      headerName: 'Editar Regras',
      width: 160,
      headerAlign: 'center',
      align: 'center',
      renderCell: ({ value }) => (
        <div
          onClick={() => setSelectedUser(combined.find((c: Person) => c.email === value) || null)}
          onKeyDown={() => setSelectedUser(combined.find((c: Person) => c.email === value) || null)}
          role="button"
          tabIndex={0}
          style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}
        >
          <EditIcon sx={{ color: '#4B4B4B', fontSize: 16 }} />
        </div>
      ),
      sortable: false,
    },
  ];
  return v;
};

function PersonProductRoles() {
  const tableEl = useRef() as unknown as MutableRefObject<HTMLInputElement>;
  const [currentPage, setCurrentPage] = useState(0);
  const [lastPage, setLastPage] = useState(-3);
  const [product, setProduct] = useState<Product | null>(null);
  const [selectedUser, setSelectedUser] = useState<Person | null>(null);

  const [totalPages, setTotalPages] = useState(100);
  const [allData, setAllData] = useState([] as Person[]);
  const [searchTerm, setSearchTerm] = useState('');
  const throttledSearchTerm = useThrottle(searchTerm, ON_CHANGE_DELAY);
  const [lastTimestamp, setLasTimestamp] = useState(0);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });

  const { data: products } = useProductsQuery();
  useEffect(() => {
    setProduct(products?.find((p) => p.name === process.env.REACT_APP_KEYCLOAK_CLIENT_ID) || null);
  }, [products]);

  const currentResult = usePersonQuery({
    searchTerm: throttledSearchTerm,
    page: paginationModel.page,
    size: paginationModel.pageSize,
    timestamp: lastTimestamp,
  });
  const tableRef = tableEl.current;

  const scrollListener = useCallback(() => {
    if (tableRef !== undefined) {
      const bottom = tableRef.scrollHeight - tableRef.clientHeight;
      if (bottom === tableRef.scrollTop && currentPage < totalPages - 1) {
        setCurrentPage(currentPage + 1);
      }
    }
  }, [currentPage, totalPages, tableRef]);

  useLayoutEffect(() => {
    const tableRef2 = tableEl.current;
    if (tableRef2 !== undefined) {
      tableRef2.addEventListener('scroll', scrollListener);
      return () => {
        tableRef2.removeEventListener('scroll', scrollListener);
      };
    }
    return () => ({});
  }, [scrollListener]);

  const combined = useMemo(() => {
    if (currentResult.data !== undefined) {
      if (currentPage === lastPage) {
        setCurrentPage(0);
        setTotalPages(currentResult.data.totalPages);
        setAllData(() => [...(currentResult?.data?.content || [])]);
        return [...(currentResult?.data?.content || [])];
      }

      setTotalPages(currentResult.data.totalPages);
      setAllData((a) => [...a, ...(currentResult?.data?.content || [])]);
      setLastPage(currentPage);
      return [...allData, ...currentResult.data.content];
    }
    return [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentResult.data, lastTimestamp]);
  const [rowCountState, setRowCountState] = useState(currentResult?.data?.totalElements || 0);

  useEffect(() => {
    setRowCountState((prevRowCountState) =>
      currentResult?.data?.totalElements !== undefined ? currentResult?.data?.totalElements : prevRowCountState,
    );
  }, [currentResult?.data?.totalElements, setRowCountState]);

  const incrementLastTimestamp = () => {
    setLasTimestamp(lastTimestamp + 1);
  };

  return (
    <div style={{ width: '100%' }}>
      <SearchBar searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
      <Drawer onBackdropClick={() => setSelectedUser(null)} open={selectedUser !== null} anchor="right">
        <CreatePersonProductRole
          closeDrawer={() => setSelectedUser(null)}
          person={selectedUser}
          incrementLastTimestamp={incrementLastTimestamp}
          product={product}
        />
      </Drawer>
      <div style={{ height: '500px', marginTop: '16px' }}>
        {combined && (
          <DataGrid
            slots={{ noRowsOverlay: myCustomNoRows }}
            disableColumnMenu
            disableColumnFilter
            rowCount={rowCountState}
            sx={{
              border: 'none',
              '& .MuiCheckbox-root.Mui-checked': {
                color: '#ff7b00',
              },
              '& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer': {
                display: 'none',
              },
              '& .MuiDataGrid-row': { cursor: 'pointer' },
            }}
            rowHeight={40}
            columnHeaderHeight={40}
            rows={combined.map(({ name, email }) => ({
              name,
              email,
              id: email,
            }))}
            columns={columns(setSelectedUser, combined)}
            pageSizeOptions={[5]}
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
            paginationMode="server"
          />
        )}
      </div>
    </div>
  );
}

export default PersonProductRoles;
