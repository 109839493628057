import { CostCenter } from '@app/models/costcenter';
import api from '@services/api';
import { PaginationResponse } from '../models/pagination';

/**
 * <p>
 * API endpoints that covers all CostCenter CRUD calls
 */

interface QueryInput {
  query: string;
  showClosed: boolean;
}

interface QueryByCodeInput {
  code?: string;
}

export const costcenterApi = api.injectEndpoints({
  endpoints: (builder) => ({
    // GET ALL
    costCenters: builder.query<PaginationResponse<CostCenter>, number>({
      query: (page) => ({
        url: '/sharing/costCenter/search',
        method: 'POST',
        body: {
          sorts: [
            {
              key: 'name',
              direction: 'ASC',
            },
          ],
          page,
          size: 15,
        },
        validateStatus: (response) => response.status === 200 || response.status === 302,
      }),
      providesTags: ['CostCenter'],
    }),
    // SEARCH BY NAME
    costCentersSearchName: builder.query<PaginationResponse<CostCenter>, QueryInput>({
      query: (queryInput) => ({
        url: `/sharing/costCenter/searchByNameOrCode?searchTerm=${queryInput.query}&bringClosed=${queryInput.showClosed}`,
        method: 'GET',
        validateStatus: (response) => response.status === 200 || response.status === 302,
      }),
      providesTags: ['CostCenter'],
    }),

    // SEARCH BY NAME
    costCentersByCode: builder.query<CostCenter, QueryByCodeInput>({
      query: (queryInput) => ({
        url: `/sharing/costCenter/byCode?code=${queryInput.code}`,
        method: 'GET',
        validateStatus: (response) => response.status === 200 || response.status === 302,
      }),
      providesTags: ['CostCenter'],
    }),
    // CREATE NEW
    addCostCenters: builder.mutation<CostCenter, CostCenter>({
      query: (costcenter) => ({
        url: '/sharing/costcenter',
        method: 'POST',
        body: costcenter,
      }),
      invalidatesTags: ['CostCenter'],
    }),
    // UPDATE
    updateCostCenters: builder.mutation<CostCenter, CostCenter>({
      query: ({ id, ...costcenter }) => ({
        url: `/sharing/costcenter/${id}`,
        // url: '/costcenter',
        method: 'PUT',
        body: costcenter,
      }),
      invalidatesTags: ['CostCenter'],
    }),
    // DELETE
    removeCostCenters: builder.mutation<void, number>({
      query: (id) => ({
        url: `/sharing/costcenter/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['CostCenter'],
    }),
  }),
});

export const {
  useCostCentersQuery,
  useCostCentersSearchNameQuery,
  useCostCentersByCodeQuery,
  useAddCostCentersMutation,
  useRemoveCostCentersMutation,
  useUpdateCostCentersMutation,
} = costcenterApi;
