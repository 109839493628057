import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react';

// Create our baseQuery instance
const baseQuery = fetchBaseQuery({
  baseUrl: process.env.BASE_URL,
  prepareHeaders: (headers) => {
    // By default, if we have a token in the local storage,
    // we must use that for authenticated requests
    const token = localStorage.getItem('token');

    // If exists, set the Authorization header
    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }
    return headers;
  },
  mode: 'cors',
});

const baseQueryWithRetry = retry(baseQuery, { maxRetries: 0 });

/**
 * Create a base API to inject endpoints into elsewhere.
 * Components using this API should import from the injected site,
 * in order to get the appropriate types,
 * and to ensure that the file injecting the endpoints is loaded
 */
const api = createApi({
  /**
   * `reducerPath` is optional and will not be required by most users.
   * This is useful if you have multiple API definitions,
   * e.g. where each has a different domain, with no interaction between endpoints.
   * Otherwise, a single API definition should be used in order to support tag invalidation,
   * among other features
   */
  reducerPath: 'splitApi',
  /**
   * A bare bones base query would just be `baseQuery: fetchBaseQuery({ baseUrl: '/' })`
   */
  baseQuery: baseQueryWithRetry,
  /**
   * Tag types must be defined in the original API definition
   * for any tags that would be provided by injected endpoints
   */
  tagTypes: [
    'CostCenter',
    'Summary',
    'Period',
    'CostCenterSearch',
    'Contract',
    'ResourceSharing',
    'CurrentPeriod',
    'Periods',
    'Products',
    'PeriodFile',
    'NotProcessContract',
  ],
  /**
   * This api has endpoints injected in adjacent files,
   * which is why no endpoints are shown below.
   * If you want all endpoints defined in the same file, they could be included here instead
   */
  endpoints: () => ({}),
  refetchOnMountOrArgChange: true,
});

export default api;
