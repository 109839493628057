import {
  ClosePeriodRequest,
  ClosePeriodResponse,
  NewPeriodRequest,
  Period,
  UpdatePeriodRequest,
} from '@app/models/period';
import api from '@services/api';

/**
 * <p>
 * API endpoints that cover all resource period related CRUD calls
 */
export const periodApi = api.injectEndpoints({
  endpoints: (builder) => ({
    // GET
    currentPeriod: builder.query<Period, void>({
      query: () => ({
        url: encodeURI(`/sharing/period/current`),
        method: 'GET',
      }),
      providesTags: ['CurrentPeriod'],
    }),
    allByYear: builder.query<Array<Period>, number>({
      query: (year) => ({
        url: encodeURI(`/sharing/period/byYear?year=${year}`),
        method: 'GET',
      }),
      providesTags: ['Periods'],
    }),
    createPeriod: builder.mutation<Period, NewPeriodRequest>({
      query: (period) => ({
        url: '/sharing/period/open',
        method: 'POST',
        body: period,
      }),
      invalidatesTags: ['Periods', 'CurrentPeriod'],
    }),
    updatePeriod: builder.mutation<Period, UpdatePeriodRequest>({
      query: (period) => ({
        url: '/sharing/period/update',
        method: 'PUT',
        body: period,
      }),
      invalidatesTags: ['Periods', 'CurrentPeriod'],
    }),

    closePeriod: builder.mutation<ClosePeriodResponse, ClosePeriodRequest>({
      query: (period) => ({
        url: '/sharing/period/close',
        method: 'POST',
        body: period,
      }),
      invalidatesTags: ['Periods', 'CurrentPeriod'],
    }),
  }),
});

export const {
  useCurrentPeriodQuery,
  useAllByYearQuery,
  useCreatePeriodMutation,
  useUpdatePeriodMutation,
  useClosePeriodMutation,
} = periodApi;
