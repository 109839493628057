import Sidebar from '@/components/sidebar/Sidebar';
import Navbar from '@components/Navbar';
import { Box } from '@mui/material';
import { Outlet } from 'react-router-dom';

function Layout() {
  return (
    <Box
      display="flex"
      width="100%"
      height="100%"
      style={{ height: '100%', maxHeight: '100%', minHeight: '100%', position: 'absolute' }}
    >
      <Box
        flexGrow={1}
        style={{
          maxHeight: '100%',
          minHeight: '100%',
          position: 'absolute',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Navbar />
        <Box
          sx={{ display: 'flex', gap: '1.5rem', paddingTop: '1.0rem' }}
          style={{ maxHeight: '100%', minHeight: '100%' }}
        >
          <Sidebar />
          <Outlet />
        </Box>
        <Box />
      </Box>
    </Box>
  );
}
export default Layout;
